/* eslint-disable no-unused-vars */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
// Notification

// eslint-disable-next-line import/prefer-default-export
export default function headersDetails() {
  const toast = useToast()
  const editAboutPageData = ref({
    type: 'Contact',
    title: '',
    description: '',
    image: '',
    language_id: '',
  })

  watchEffect(() => store.dispatch('headersModule/getAboutPageData', { id: editAboutPageData.value.type, code: editAboutPageData.value.language_id }))

  const getHead = computed(() => store.state.headersModule.subclassifications)

  return {
    toast,
    editAboutPageData,
    getHead,
  }
}
