<template>
  <div>
    <b-card>
      <validation-observer
        ref="infoRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="cat"
            >
              <b-form-group
                label="Select Page To change Header"
                label-for="cat"
              >
                <v-select
                  v-model="editAboutPageData.type"
                  label="name"
                  :reduce="cat => cat.type"
                  :options="categoriesList"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              name="languages"
              rules="required"
            >
              <b-form-group
                label-for="languages"
              >
                <label class="selectLanguage"> Plese Select the Language </label>
                <v-select
                  id="languages"
                  v-model="editAboutPageData.language_id"
                  label="name"
                  required
                  :reduce="lang => lang.code"
                  :options="languagesList"
                  class="select-size-lg"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <select-language :selected-language="editAboutPageData" /> -->
          <b-col md="6">
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Title"
                label-for="title_head"
              >
                <b-form-input
                  id="title_head"
                  v-model="getHead.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Title"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Name"
            >
              <b-form-group
                label="Description"
                label-for="desc"
              >
                <b-form-textarea
                  id="desc"
                  v-model="getHead.description"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Description"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Image"
            >
              <b-form-file
                accept=".jpg, .png"
                placeholder="Choose Image"
                multiple
                @change="onChangePageHeadImage"
              />
              <div
                v-if="preview"
                class="mt-2"
              >
                <img
                  :src="preview"
                  class="img-fluid"
                  width="100"
                >
              </div>
              <div
                v-else
                class="mt-2"
              >
                <img
                  :src="getHead.image"
                  class="img-fluid"
                  width="100"
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="addAboutPAgeData()"
          >
            Save
          </b-button>
        </b-col>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */

import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive, computed,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BImg, BFormFile, BCardText, BMediaBody, BMediaAside, BFormSelectOption,
  BDropdown, BDropdownItem, BPagination, BFormGroup, BFormInvalidFeedback, BFormTextarea, BFormSelect, VBModal, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import VueFileAgent from 'vue-file-agent'
import vSelect from 'vue-select'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import store from '@/store'
import handleFormData from '@/common/compositions/handleFormData'
import headersModule from './headersModule'
import headers from './headers'
import selectLanguage from '@/common/components/SelectLanguage.vue'

Vue.use(VueSweetalert2)
Vue.use(VueFileAgent)

export default {
  components: {
    selectLanguage,
    BCard,
    BFormSelectOption,
    BMediaAside,
    BCardText,
    BMediaBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BForm,
    quillEditor,
    VueFileAgentStyles,
  },
  data() {
    return {
      preview: null,
      image: null,
      previewNotified: null,
      imageNotified: null,
      previewKey: null,
      imageKey: null,
      fileRecords: [],
      fileRecordsForUpload: [],
      categoriesList: [
        {
          type: 'Contact',
          name: 'Contact',
        },
        {
          type: 'Service',
          name: 'Service',
        },
        {
          type: 'Product',
          name: 'Product',
        },
        {
          type: 'Event',
          name: 'Event',
        },
        {
          type: 'Blog',
          name: 'Blog',
        },
      ],
      languagesList: [],
    }
  },
  setup() {
    const HEADERS_MODULE = 'headersModule'

    // Register module
    if (!store.hasModule(HEADERS_MODULE)) store.registerModule(HEADERS_MODULE, headersModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HEADERS_MODULE)) store.unregisterModule(HEADERS_MODULE)
    })

    const { editAboutPageData, getHead } = headers()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      editAboutPageData,
      getHead,
    }
  },
  created() {
    this.getLanguages()
  },
  methods: {
    async getLanguages() {
      const response = await axios.get('get/language')
      this.languagesList = response.data.success
    },
    onChangePageHeadImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.getHead.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    addAboutPAgeData() {
      const formData = new FormData()
      formData.append('type', this.getHead.type)
      formData.append('title', this.getHead.title)
      formData.append('description', this.getHead.description)
      formData.append('image', this.getHead.image)
      formData.append('language_id', this.getHead.language_id)
      axios.post('add/header', formData).then(res => {
        if (res.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).catch(error => {
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Somthing Went Wrong',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
